import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Producto } from '../Models/Productos';
import { TiktokEventsService } from './tiktok-events.service';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class CarritoServiceService {

  placeholder: any[] =[];
  cartItems =new BehaviorSubject<any>([]);
  cartItems2 =new BehaviorSubject<any>([]);
  cartItems3 =new BehaviorSubject<any>([]);
  constructor(
    public http: HttpClient,
    private tiktokEvents: TiktokEventsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 

    /*afterRender(()=>{
      const ls = this.getCartData();
      if(ls){
        this.cartItems.next(ls);
      }
    }) */

    if (isPlatformBrowser(this.platformId)) {
      const ls = this.getCartData();
      const ls2 = this.getCartData2();
      const ls3 = this.getCartData3();
      if(ls){
        this.cartItems.next(ls);
      }
      if(ls2){
        this.cartItems2.next(ls2);
      }
      if(ls3){
        this.cartItems3.next(ls3);
      }
    }
  }

  addItem(product: Producto){

    this.tiktokEvents.sendEvent({
      event: 'AddToCart',
      value: product.precio
    });

    const ls = this.getCartData();
    let exist: any;
    let pasa : any;
  
    pasa = false;
    
    if (ls) {
      ls.find((item:any) =>{
       
        if (!pasa) {
          
          pasa = (item.sku === product.sku);
          if (pasa) {
            exist = item;
          }
          
        }
      });
      if(pasa){
        exist.quantity+= product.quantity;
        this.setCartData(ls);
        
      }else{
        ls.push(product);
        this.setCartData(ls);
      }
    }else{

        const newData = [product];
        this.placeholder.push(product);
        this.setCartData(newData);

    }
  }

  addItem2(product: Producto){

    this.tiktokEvents.sendEvent({
      event: 'AddToCart',
      value: product.precio
    });

    const ls = this.getCartData2();
    let exist: any;
    let pasa : any;
  
    pasa = false;
    
    if (ls) {
      ls.find((item:any) =>{
       
        if (!pasa) {
          
          pasa = (item.sku === product.sku);
          if (pasa) {
            exist = item;
          }
          
        }
      });
      if(pasa){
        exist.quantity+= product.quantity;
        this.setCartData2(ls);
        
      }else{
        ls.push(product);
        this.setCartData2(ls);
      }
    }else{

        const newData = [product];
        this.placeholder.push(product);
        this.setCartData2(newData);

    }
  }


  addItem3(product: Producto){

    this.tiktokEvents.sendEvent({
      event: 'AddToCart',
      value: product.precio
    });

    const ls = this.getCartData3();
    let exist: any;
    let pasa : any;
  
    pasa = false;
    
    if (ls) {
      ls.find((item:any) =>{
       
        if (!pasa) {
          
          pasa = (item.sku === product.sku);
          if (pasa) {
            exist = item;
          }
          
        }
      });
      if(pasa){
        exist.quantity+= product.quantity;
        this.setCartData3(ls);
        
      }else{
        ls.push(product);
        this.setCartData3(ls);
      }
    }else{

        const newData = [product];
        this.placeholder.push(product);
        this.setCartData3(newData);

    }
  }

  setCartData2(data:any){
    localStorage.setItem("mesa_regalos", JSON.stringify(data));
      this.cartItems2.next(this.getCartData2());
    }

    
  setCartData3(data:any){
    localStorage.setItem("item_regalo", JSON.stringify(data));
      this.cartItems3.next(this.getCartData3());
    }

  setCartData(data:any){
    localStorage.setItem("cart", JSON.stringify(data));
      this.cartItems.next(this.getCartData());
    }
    
    getCartData(){
      return JSON.parse(localStorage.getItem("cart")+"");
    }

    getCartData2(){
      return JSON.parse(localStorage.getItem("mesa_regalos")+"");
    }

    getCartData3(){
      return JSON.parse(localStorage.getItem("item_regalo")+"");
    }
    deleteCart(){
      this.placeholder = [];
      this.cartItems.next(this.placeholder);
      localStorage.removeItem('cart');
    }

    deleteCart2(){
      this.placeholder = [];
      this.cartItems2.next(this.placeholder);
      localStorage.removeItem('mesa_regalos');
    }


    deleteCart3(){
      this.placeholder = [];
      this.cartItems3.next(this.placeholder);
      localStorage.removeItem('item_regalo');
    }



    registraNuevoCarrito(email: any){
      return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoCreado.php',email,httpOption);
    }

    agregaCarrito(datosCarro: any){
      return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/agregaCarrito.php',datosCarro);
    }

    updateCarrito(datosCarro: any){
      return this.http.post('https://new.lideart.com.mx/Automatizacion/apis/updateCarrito.php',datosCarro);
    }

    getCarritoAgregado(correo:string){
      return this.http.get<Response>('https://new.lideart.com.mx/Automatizacion/apis/getCarritoAgregado.php?email='+correo);
  
    }
    
    carritoPasoCaja(email: any){
      return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoCaja.php',email,httpOption);
    }
    
    carritoPasoUsuario(email: any){
      return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoUsuario.php',email,httpOption);
    }
    
    carritoPasoDireccion(email: any){
      return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoDireccion.php',email,httpOption);
    }
    
    carritoPasoEnvio(email: any){
      return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoPasoEnvio.php',email,httpOption);
    }
    
    carritoFinalizo(email: any){
      return this.http.post<Response>('https://new.lideart.com.mx/Automatizacion/apis/carritoFinalizo.php',email,httpOption);
    }


}
